import React, { useEffect, useRef } from 'react'

import { useTranslation } from 'gatsby-plugin-react-i18next'

import Polaris from 'src/lib/Polaris'

import { SlideinWrap, SlideinItem } from 'src/components/slidein/index'

import Image from 'src/components/image/index'

import styles from './gelatoria.module.scss'

const TopGridSpa = () => {
	const { t } = useTranslation()

	const p1 = useRef()
	const p2 = useRef()
	const p3 = useRef()
	const p4 = useRef()
	const p5 = useRef()
	const p6 = useRef()

	useEffect(() => {
		const resizeID = Polaris.util.onResize(() => {
			p1.current.style.height = ''
			p2.current.style.height = ''
			p3.current.style.height = ''
			p4.current.style.height = ''
			// p5.current.style.height = ''
			// p6.current.style.height = ''

			const h1 = Math.max(p1.current.clientHeight, p3.current.clientHeight) + 'px'
			p1.current.style.height = h1
			p3.current.style.height = h1

			const h2 = Math.max(p2.current.clientHeight, p4.current.clientHeight) + 'px'
			p2.current.style.height = h2
			p4.current.style.height = h2

			// const h3 = Math.max(p5.current.clientHeight, p6.current.clientHeight) + 'px'
			// p5.current.style.height = h3
			// p6.current.style.height = h3
		})

		return () => {
			Polaris.util.offResize(resizeID)
		}
	}, [])

	return (
		<div className={styles.grid}>
			<div className={styles.image1}>
				<Image src="/top/gelato-and-bake_img02.jpg" alt="" />
			</div>
			<div className={styles.block1}>
				<SlideinWrap>
					<SlideinItem delay={0}>
						<h3 dangerouslySetInnerHTML={{ __html: t(`gelato.block1.title`) }}></h3>
					</SlideinItem>
					<SlideinItem delay={200}>
						<p ref={p1} dangerouslySetInnerHTML={{ __html: t(`gelato.block1.text`) }}></p>
					</SlideinItem>
				</SlideinWrap>
			</div>

			<div className={styles.image2}>
				<Image src="/top/gelato-and-bake_img03.jpg" alt="" />
			</div>
			<div className={styles.block2}>
				<SlideinWrap>
					<SlideinItem delay={0}>
						<h3 dangerouslySetInnerHTML={{ __html: t(`gelato.block2.title`) }}></h3>
					</SlideinItem>
					<SlideinItem delay={200}>
						<p ref={p2} dangerouslySetInnerHTML={{ __html: t(`gelato.block2.text`) }}></p>
					</SlideinItem>
				</SlideinWrap>
			</div>

			<div className={styles.image3}>
				<Image src="/top/gelato-and-bake_img04.jpg" alt="" />
			</div>
			<div className={styles.block3}>
				<SlideinWrap>
					<SlideinItem delay={0}>
						<h3 dangerouslySetInnerHTML={{ __html: t(`gelato.block3.title`) }}></h3>
					</SlideinItem>
					<SlideinItem delay={200}>
						<p ref={p3} dangerouslySetInnerHTML={{ __html: t(`gelato.block3.text`) }}></p>
					</SlideinItem>
				</SlideinWrap>
			</div>

			<div className={styles.image4}>
				<Image src="/top/gelato-and-bake_img05.jpg" alt="" />
			</div>
			<div className={styles.block4}>
				<SlideinWrap>
					<SlideinItem delay={0}>
						<h3 dangerouslySetInnerHTML={{ __html: t(`gelato.block4.title`) }}></h3>
					</SlideinItem>
					<SlideinItem delay={200}>
						<p ref={p4} dangerouslySetInnerHTML={{ __html: t(`gelato.block4.text`) }}></p>
					</SlideinItem>
				</SlideinWrap>
			</div>

			<div className={styles.image5}>
				<Image src="/top/gelato-and-bake_img06.jpg" alt={t(`gelato.block5.title`)} />
			</div>
			<div className={styles.block5}>
				<SlideinWrap>
					<SlideinItem delay={0}>
						<h3 dangerouslySetInnerHTML={{ __html: t(`gelato.block5.title`) }}></h3>
					</SlideinItem>
					<SlideinItem delay={200}>
						<p ref={p6} dangerouslySetInnerHTML={{ __html: t(`gelato.block5.text`) }}></p>
					</SlideinItem>
				</SlideinWrap>
			</div>

			{/* <div className={styles.image6}>
				<Image src="/top/gelato-and-bake_img07.jpg" alt={t(`gelato.block6.title`)} />
			</div>
			<div className={styles.block6}>
				<SlideinWrap>
					<SlideinItem delay={0}>
						<h3 dangerouslySetInnerHTML={{ __html: t(`gelato.block6.title`) }}></h3>
					</SlideinItem>
					<SlideinItem delay={200}>
						<p ref={p5} dangerouslySetInnerHTML={{ __html: t(`gelato.block6.text`) }}></p>
					</SlideinItem>
				</SlideinWrap>
			</div> */}
		</div>
	)
}

export default TopGridSpa
